<template>
  <div class="CreditsCardMenuContent">
    <LargeButton icon="bars" :label="$locale['credits_history']" @click="goTo($links['my-credits-history'])" />
    <LargeButton icon="credit_card" :label="$locale['credits_reload']" @click="reloadCredits" />
  </div>
</template>

<script>
export default {
  props: ["modal", "cardModal"],
  methods: {
    goTo: function(to, cb) {
      if (Go.is(this.cardModal && this.cardModal.close, "Function")) {
        this.cardModal.close(() => {
          this.modalNav(to);
          if (Go.is(cb, "Function")) cb();
        });
        return;
      }

      this.modalNav(to);
      if (Go.is(cb, "Function")) cb();
    },
    reloadCredits: function() {
      this.goTo(this.$links["my-credits"], () => {
        Go.scrollIntoView(".user-credits-card");
      });
    },
  },
};
</script>

<style lang="scss">
.CreditsCardMenu {
  .CreditsCardMenuContent {
    position: relative;
  }
}
</style>
